import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../components/layout'
import Page from '../templates/Page'
import PageSection from '../templates/PageSection'
import Contact from '../components/Contact'
import FreeTrial from '../components/FreeTrial'

import './broadcaster-resources.css'
import spacialLogo from '../assets/old-images/broadcaster-resources/special-logo.png'
import musicMasterLogo from '../assets/old-images/broadcaster-resources/music-master-logo.png'
import voiceBunnyLogo from '../assets/old-images/broadcaster-resources/voice-bunny-logo.png'
import benztownLogo from '../assets/old-images/broadcaster-resources/benztown-logo.png'
import fmLogo from '../assets/old-images/broadcaster-resources/fm-logo.png'

class BroadcasterResources extends React.Component {
  render() {
    return (
      <Page
        className={classNames('broadcaster-resources')}
        headerTitle="Broadcaster Resources"
        withHeaderBackgroundImage
        header={null}
      >
        <PageSection className={classNames('hardware-software')}>
          <div className={classNames('column', 'padded-section-content')}>
            <h1 className={classNames('section-title')}>
              Hardware &amp; Software
            </h1>
            <RowLayout isResponsive itemSpacing={'8vw'}>
              <Fade top duration={700}>
                <RowLayout
                  isResponsive
                  itemSpacing={'4vw'}
                  className={classNames('section-element', 'spacial')}
                >
                  <div className={classNames('element-image')}>
                    <a href="https://spacial.com/sam-broadcaster-pro/">
                      <img src={spacialLogo} alt="Spacial" />
                    </a>
                  </div>
                  <p className={classNames('element-description')}>
                    One of the best known names in Internet radio solutions, SAM
                    Broadcaster has everything you need to run your station
                    professionally in one elegant software package.
                  </p>
                </RowLayout>
              </Fade>
              <Fade top duration={700} delay={200}>
                <RowLayout
                  isResponsive
                  itemSpacing={'4vw'}
                  className={classNames('section-element', 'music-master')}
                >
                  <div className={classNames('element-image')}>
                    <a href="https://www.musicmaster.com/">
                      <img src={musicMasterLogo} alt="MusicMaster" />
                    </a>
                  </div>
                  <p className={classNames('element-description')}>
                    MusicMaster is the fastest growing and most powerful music
                    scheduling software for radio stations worldwide.
                  </p>
                </RowLayout>
              </Fade>
            </RowLayout>
          </div>
        </PageSection>

        <PageSection className={classNames('voice-imaging')}>
          <div className={classNames('column', 'padded-section-content')}>
            <h1 className={classNames('section-title')}>Voice &amp; Imaging</h1>
            <RowLayout isResponsive itemSpacing={'8vw'}>
              <Fade top duration={700}>
                <RowLayout
                  isResponsive
                  itemSpacing={'4vw'}
                  className={classNames('section-element', 'voice-bunny')}
                >
                  <div className={classNames('element-image')}>
                    <a href="https://voicebunny.com/">
                      <img src={voiceBunnyLogo} alt="VoiceBunny" />
                    </a>
                  </div>
                  <p className={classNames('element-description')}>
                    VoiceBunny is the most reliable and efficient service to get
                    high-quality voice overs for your station. Choose from
                    28,000 voice actors and more than 50 languages and dialects.
                  </p>
                </RowLayout>
              </Fade>
              <Fade top duration={700} delay={100}>
                <RowLayout
                  isResponsive
                  itemSpacing={'4vw'}
                  className={classNames('section-element', 'benztown')}
                >
                  <div className={classNames('element-image')}>
                    <a href="https://www.benztown.com/">
                      <img src={benztownLogo} alt="Benztown" />
                    </a>
                  </div>
                  <p className={classNames('element-description')}>
                    Benztown is a leading international radio imaging,
                    production library, programming, jingles and voiceover
                    services company with over 1900 affiliations on six
                    continents.
                  </p>
                </RowLayout>
              </Fade>
            </RowLayout>
          </div>
        </PageSection>

        <PageSection className={classNames('web-design')}>
          <div className={classNames('column', 'padded-section-content')}>
            <h1 className={classNames('section-title')}>Web &amp; Design</h1>
            <RowLayout isResponsive itemSpacing={'8vw'}>
              <Fade top duration={700}>
                <RowLayout
                  isResponsive
                  itemSpacing={'4vw'}
                  className={classNames('section-element', 'dot-fm')}
                >
                  <div className={classNames('element-image')}>
                    <a href="https://dot.fm/">
                      <img src={fmLogo} alt="dot fm" />
                    </a>
                  </div>
                  <p className={classNames('element-description')}>
                    What better way to show off your station and website than
                    with a .fm domain. dotFM provides a number of domain
                    management and email services geared towards radio.
                  </p>
                </RowLayout>
              </Fade>
            </RowLayout>
          </div>
        </PageSection>

        <JoinPrompt />

        <Contact
          className={classNames('page-section')}
          description="Our partnership team is here to work with you."
        />
      </Page>
    )
  }
}

const JoinPrompt = () => (
  <PageSection
    className={classNames(
      'broadcaster-resources',
      'join-prompt',
      'with-background-image',
      'light-on-dark'
    )}
  >
    <div
      className={classNames(
        'column',
        'alignment-container',
        'padded-section-content'
      )}
    >
      <Fade top duration={700} fraction={0.9}>
        <h1>Join us and start broadcasting today.</h1>
        <FreeTrial />
      </Fade>
    </div>
  </PageSection>
)

export default BroadcasterResources
